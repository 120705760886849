.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.mmcq__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.mmcq {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
}

.mmcqCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.mmcqCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.mmcqCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.mmcqCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 42px !important;
}

/*  */

.main_div {
  display: flex;
  padding:20px;
}

.ans_mmcq_image {
  width: 130px;
  height: auto;
  border-radius: 10px;
}

.imgBlock {
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  max-width: 80px !important;
  height: auto !important;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: -34px;
}

.opt-card-top {
  height: fit-content !important;
  width: 100% !important;
}

@media (max-width: 450px) {
  .main_div {
    flex-direction: column;
  }
  .mmcq > .up {
    width: 90% !important;
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    display: inline !important;
  }
  .mmcq {
    width: 95% !important;
    /* margin-left: auto!important;
      margin-right: auto!important; */
  }
  .mmcqCard__left {
    text-align: left !important;
  }
  .top-left,
  .bottom-left {
    margin-left: -15px !important;
  }
  /* .top-right,.bottom-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
  .opt1-container {
    width: 100% !important;
    font-size: 13px !important;
  }
  /* .ml-6 {
        margin-left: 0px!important;
    } */
}

@media (max-width: 768px) {
  /*.main_div img {
    width: 80% !important;
    height: auto !important;
    margin: 0 !important;
    max-width: fit-content;
    max-height: fit-content;
    margin-left: -25px !important;
    margin-top: 5px !important;
  }*/
  .mmcq {
    flex-direction: column;
    margin: 0 !important;
  }
  .up {
    width: 90% !important;
  }
  .ml {
    margin-left: 0 !important;
  }
  .mmcq {
    padding-left: 20px !important;
  }
}

.question_image {
    display: flex;
    width: 250px;
    height:300px;
    padding:10px;
    margin: auto;
}

.question_image > .q-img {
    width: fit-content;
    max-width: 100%;
    max-height: 100%;
    margin: auto !important;
}