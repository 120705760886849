.Login {
    margin: 0px;
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0px;
    z-index: 0;
}

.bg-1 {
    width: 100%;
    height: 150%;
}

.wave {
    position: absolute;
    top: 0;
    z-index: 0;
    opacity: 0.4;
    width: 100%;
    height: 55vh !important;
}