.superhead {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.one__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.oneWord {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: inherit;
  align-items: center;
  margin: 20px 35px;
}

.oneWord > input {
  width: 350px;
  height: 40px;
  border: 1px solid #f28584;
  margin: auto;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 15px;
}

.oneCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.oneCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
}

.oneCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.oneCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.oneword_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 20px;
}

.signarture {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 0px;
  width: 225.54px;
  height: 55px;
  background: #ffffff;
  border-bottom: 3px solid #f28584;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: -5px;
  margin-bottom: 10px;
  margin-right: 18px;
  margin-left: auto;
  order: 2;
  padding-left: 5px;
}

.last {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .oneWord {
    padding-left: 10px !important;
  }
  .oneWord > input {
    margin-top: 10px;
    margin-right: 22px;
    margin-left: 0px;
    max-width: 250px !important;
  }
  .oneword_image {
    margin-left: 0px;
  }
  .oneCard__left {
    font-size: 0.9em !important;
  }
}

@media (max-width: 768px) {
  .remark {
    margin-left: 0 !important;
  }
}

.question_image {
  display: flex;
  width: 300px !important;
  height: 300px !important;
  padding: 10px;
  margin:0 !important;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto !important;
}
