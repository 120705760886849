.recharts-cartesian-axis-tick-value {
    font-size: 0.7em !important;
}

.recharts-label {
    font-size: 0.75em !important;
}

.recharts-legend-wrapper {
    bottom: 50px !important;
    left: 50px !important;
}

.recharts-legend-item {
    font-size: 0.8em !important;
}

@media screen and (max-width:472px) {
    .recharts-cartesian-axis-tick-value {
        font-size: 0.6em !important;
    }
    .recharts-label {
        font-size: 0.7em !important;
    }
    .recharts-legend-wrapper {
        bottom: 0px !important;
        left: 0px !important;
    }
}