.reportCardFilter .filterDivider {
    background-color: black;
    width: 100%;
    height: 2px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.reportCardFilter {
    border: 2px solid black !important;
    border-radius: 10px;
}