.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.desc__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.desc {
    display: flex;
    justify-content: space-around;
}

.desc_image {
    height: auto;
    width: 350px;
    margin-top: auto;
}

.upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.upload>textarea {
    width: 553px;
    height: 248px;
    border: 1px solid #F59B92;
    box-sizing: border-box;
    border-radius: 10px;
}

.or {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bolder;
}

.upload_btn {
    display: flex;
    width: 135px;
    font-size: 18px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    height: 35px;
    background: #F28584;
    border-radius: 35px;
    border: 1px solid #F28584;
}

.upload_btn>img {
    width: 20px;
    height: 20px;
}

.button_details {
    height: 35px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    padding-left: 1px;
    align-items: center;
    text-align: center;
    color: #212121;
    opacity: 0.5
}

.descCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.descCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
}

.descCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F28584;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
    ;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}