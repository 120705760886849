.language_button_container {
    position: absolute;
    bottom: 5px;
    left: 15px;
    display: flex;
    flex-direction: column;
    font-family: "Poppins" !important;
}

.language_button {
    width: 40px;
    height: 40px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    border: none;
    background: rgba(224, 224, 224, 1);
    transition: 0.25s;
}

.language_button:hover {
    transform: scale(1.05);
}

.selected_language {
    background: rgba(92, 224, 210, 1);
}

.language_button:nth-child(2) {
    margin-left: 5px;
}