.select__indicator-separator {
    display: none !important;
}

.test_list_filter {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
}

.MuiOutlinedInput-input {
    padding: 9px !important;
}

#test_filter_date_picker-label {
    margin-top: -10px !important;
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 7px 0px #0000005E;
    border-radius: 10px;
    padding: 10px;
}

.search_container {
    width: 100%;
    text-align: right;
}

#searchbar .MuiInput-underline::after {
    border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
    .learningResources {
        padding: 0px !important;
    }
    .test_list_filter {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .test_list_filter .select__value-container {
        width: 250px !important;
    }
    .test_list_filter .makeStyles-select-7,
    .test_list_filter .makeStyles-datePicker-8 {
        width: fit-content !important;
        margin-bottom: 7px;
    }
    .test_list_filter .makeStyles-7 {
        padding: 0 !important;
    }
    .test_list_upper_box div h1 {
        font-size: 1.4em;
        text-align: center;
    }
    .test_list_upper_box div h3 {
        font-size: 1.1em;
        margin: 15px auto;
        text-align: center;
    }
}