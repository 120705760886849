.learningResources .box {
  min-height: 100px;
  background-color: #5ce0d2;
  padding: 25px;
  border-radius: 8px;
}

.learningResources .cardData {
  padding-top: 242px;
  color: black;
}

.learningResources .cardData p {
  text-decoration: none;
}

.cardData p:first-child {
  margin-block-start: 0px !important;
  margin-block-end: 5px !important;
}

.cardData p :nth-child(2) {
  margin-block-start: 0px !important;
  margin-block-end: 20px !important;
}

.white_color {
  color: "white" !important;
}

.button_container {
  display: flex;
}
.button_container > button {
    border: transparent;
    width: fit-content;
    border-radius: 5px;
    margin-right: 10px;
    padding:8px 15px;
}

@media screen and (max-width: 472px) {
  .learningResources {
    padding: 0px !important;
  }
}
