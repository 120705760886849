.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.mcq__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.mcq {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
}

.mcqCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
}

.mcqCard__left {
    display: flex;
    padding-left: 18px;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.mcqCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.mcqCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.checkbox>.MuiIconButton-label>.MuiSvgIcon-root {
    color: #F28584 !important;
    font-size: 42px !important;
}

.opt1-container {
    border: 1px solid #F59B92;
    padding: 10px;
    width: 50%;
    /* change*/
    /* height: 50px; */
    height: fit-content;
    padding-left: 68px;
    border-radius: 10px;
    display: block;
    position: relative;
    padding-left: 44px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 16px;
}

.opt1-container:hover {
    background: #CDFFE1;
}

.up {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
    /* width: 100%; */
    margin-bottom: -12px;
    margin-top: 50px;
}


/* Hide the browser's default radio button */

.opt1-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 12px;
    left: 17px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 3px solid #F59B92;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.opt1-container:hover input~.checkmark {
    background-color: #F28584;
}


/* When the radio button is checked, add a blue background */

.opt1-container input:checked~.checkmark {
    background-color: #F28584;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.opt1-container input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.opt1-container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #F28584;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F59B92;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}

.top-left {
    flex: 40%;
}

.top-right {
    flex: 40%;
    justify-content: right;
    padding-right: 20px;
}

.bottom-left {
    flex: 40%;
}

.bottom-right {
    flex: 40%;
    justify-content: right;
    padding-right: 20px;
}

.main_div {
    display: flex;
}

.mcq_image {
    height: auto;
    width: 300px;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: auto;
    margin-bottom: 30px;
    /* margin-bottom: auto; */
}

.ans_mcq_image {
    width: 130px;
    height: auto;
    border-radius: 10px;
}

.imgBlock {
    background-size: cover!important;
    position: relative;
    overflow: hidden;
    min-width: 130px;
    min-height: 100px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: -34px;
}

@media (max-width: 450px) {
    .main_div {
        flex-direction: column;
    }
    /* .mcq > .up{
      width: 45% !important;
    }
    .top-left,.top-right,.bottom-left,.bottom-right{
      display: none!important;
    }
    .mcq{
      width: 95%!important;
    }
    .top-left,.bottom-left{
      margin-left: -15px!important;
    }
    .top-right,.bottom-right{
      padding-right: 1px!important;
    }
    .opt1-container{
      width: 100%!important;
      font-size: 13px!important;
    } */
    .mcq>.up {
        width: 55% !important;
    }
    .mcq {
        width: 95%!important;
        display: flex!important;
        flex-direction: column!important;
        padding-left: 15px!important;
    }
    .top-right,
    .bottom-right {
        margin-left: -15px!important;
        padding-right: inherit!important;
    }
    .checkmark {
        top: 10px!important;
        left: 10px!important;
    }
    .opt1-container {
        padding-left: 35px!important;
        font-size: 10px!important;
    }
    /* .top-left,.bottom-left{
      margin-left: 6px!important;
    } */
    .mcqCard__left {
        text-align: left !important;
        margin-left: 10px;
    }
    .up {
        margin-top: 10px !important;
    }
}

@media(max-width:768px) {
    .mcqCard__left {
        padding: 0;
        display: block;
    }
    .mcq_image {
        width: 80%;
        height: auto !important;
        object-fit: cover;
        margin-left: 0;
    }
    .remark {
        margin: 0 !important;
    }
}

.question_image {
    display: flex;
    width: 250px;
    height:300px;
    padding:10px;
    margin: auto;
}

.question_image > .q-img {
    max-width: 100%;
    max-height: 100%;
    margin: auto !important;
}