.dashboard .box .upperBox {
    height: 200px;
    background-color: #5ce0d2;
    padding: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 3rem;
}

.makeStyles-root-28 {
    background-color: #edfcfa !important;
}

.MuiPaper-rounded {
    border-radius: 0 0 15px 15px !important;
}

.MuiTab-root {
    text-transform: none !important;
    font-weight: bold !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiCard-root {
    border: 0.5px solid rgba(33, 33, 33, 0.3) !important;
}

.dashboard_container {
    width: 78% !important;
}

.MuiTabs-indicator {
    border: 3px solid rgba(127, 61, 255, 1);
    border-radius: 23px;
    /* width: 53px !important;
    left:  600px !important; */
}

@media (min-width: 768px) {
    .dashboard .MuiTabs-flexContainer {
        justify-content: center;
    }
    .dashboard_container {
        width: 85%;
    }
}

@media screen and (max-width:420px) {
    .dashboard_container {
        width: 100% !important;
    }
    .upperBox div div h5 {
        margin: auto !important;
    }
    #dashboard_assessment_types .MuiBox-root,
    #dashboard_assessment_types .MuiBox-root .learningResources {
        padding: 24px 0px !important;
    }
}