.assesment .cardData {
    padding-top: 242px;
    color: white;
}

.tests-links {
    display: flex;
    margin: 1.5rem 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.tests-links p {
    font-size: 20px;
    margin: 0;
    text-align: center;
}

.link-box {
    width: 29%;
    /* height: 5rem; */
    padding: 10px;
    background: #F08080;
    border-radius: 7px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2rem;
    text-decoration: none;
}

.secondary-box {
    background: #C08497;
}

@media(max-width:768px) {
    .link-box {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;
    }
    .tests-links {
        margin: 0.5rem;
    }
}