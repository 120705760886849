.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.fb__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
  margin-bottom: 10px;
}

.fb {
  margin-left: 35px;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top:15px;
  margin: inherit;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.fb > .fb-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.fb input {
  width: 350px;
  height: 40px;
  border: 1px solid #f59b92;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 15px;
}

.fbCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.fbCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
}

.fbCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.fbCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.fb_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 20px;
}

.signarture {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 6px;
  width: 225.54px;
  height: 55px;
  background: #ffffff;
  border-bottom: 3px solid#F28584;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 18px;
  margin-left: auto;
  order: 2;
  padding-left: 5px;
}

.last {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .fb {
    padding-left: 10px !important;
  }
  .fb > input {
    margin-top: 10px;
    margin-right: 22px;
    margin-left: 0px;
  }
  .fb_image {
    margin-left: 0px;
  }
  .fbCard__left {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .fb_image {
    height: auto !important;
    width: 90% !important;
  }
  .fb input {
    margin-right: 0;
    width: 90%;
  }
}

.question_image {
  display: flex;
  width: 300px !important;
  height: 300px !important;
  padding: 10px;
  margin: 0 !important;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin:auto !important;
}
