.updates .inputBox {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    min-height: 75px;
    padding: 14px;
}

.updates .inputBox .inputField {
    width: 74vw;
}

.updates .inputBox .userImage {
    font-size: 60px;
}

.MuiCardHeader-title {
    font-size: 1.2em !important;
    font-family: "Poppins" !important;
    font-weight: bold !important;
}

.MuiCardHeader-subheader {
    font-family: "Poppins" !important;
}

@media only screen and (max-width: 600px) {
    .updates .inputBox .inputField {
        width: auto;
    }
    .updates .inputBox .userImage {
        font-size: 48px;
    }
    .postCard {
        margin-top: -20px !important;
    }
}

.postCard {
    margin-top: 40px;
}

.postCard .input-group input[type="text"] {
    border: 2px solid #e0dcd8;
    border-radius: 40px;
    border-right: none;
}

.postCard .input-group button {
    border: 2px solid #e0dcd8;
    border-radius: 40px;
    border-left: none;
}

.postCard .namePara {
    margin-bottom: 2px;
}