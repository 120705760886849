@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::before {
  display: block;
  content: "";
  height: 60px;
}

body {
  position: static !important;
}

* {
  font-family: "Poppins" !important;
}

.dropdown-item:active {
  background-color: #c4c7ca !important;
}

a:hover {
  color: black !important;
}

.MuiTableCell-root,
.MuiTableCell-head {
  font-family: "Poppins" !important;
}

.MuiTableCell-head {
  color: #53c2b7 !important;
}

.MuiFormControl-root,
.MuiTextField-root {
  font-family: "Poppins" !important;
}

.card-radius {
  border-radius: 17px !important;
  box-shadow: #00000040 0px 0px 5px 1px;
}

.card-height {
  min-height: 190px !important;
}

.card-img {
  height: 115px;
  padding: 5px;
}

.card {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  border: 0.5px solid #5ce0d2;
  box-shadow: 0px 1px 7px 0px #5ce0d2;
}

.card:hover {
  transform: scale(1.1);
}

.card-thumbnail {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.mar-p {
  margin: 0;
  margin-bottom: 5px;
}

@media screen and (max-width: 472px) {
  .card {
    width: 100% !important;
    margin: auto;
  }
  .learningResources {
    margin-top: -10px !important;
  }
  .learningResources div div h5 {
    text-align: center !important;
    font-size: 0.8em !important;
  }
  .learningResources div div span {
    margin: auto !important;
  }
  .learningResources div div span h1 {
    font-size: 1.4em !important;
  }
  .container {
    margin-top: 20px !important;
  }
  .MuiBox-root {
    padding: 24px 0px !important;
  }
  .card:hover {
    transform: scale(1.01);
  }
  .MuiTabs-flexContainer {
    justify-content: space-evenly !important;
  }
}

/* Change the font size and font family */
#google_translate_element {
  position: relative;
  padding: 15px 2.5% 15px 2.5%;
  overflow: hidden;
  background-color: rgb(237, 252, 250);
}
#google_translate_element .goog-te-gadget {
  width: 200px;
  overflow: hidden;
  margin: auto;
}
#google_translate_element select {
  width: 100%;
  font-size: 16px;
  color:  #7F3DFF;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: transparent;
}

/* Change the background color of the button */
#google_translate_element div.goog-te-gadget-simple {
  background-color: #f5f5f5;
}

/* Change the color of the button text */
#google_translate_element div.goog-te-gadget-simple span {
  color: #333;
}

/* Change the hover color of the button */
#google_translate_element div.goog-te-gadget-simple:hover {
  background-color: #333;
}

/* Change the hover color of the button text */
#google_translate_element div.goog-te-gadget-simple:hover span {
  color: #f5f5f5;
}

.goog-te-gadget > span:nth-child(2) {
  display: none !important;
}
.goog-te-banner-frame {
  display: none !important;
}

.skiptranslate > iframe {
  display: none !important;
}

@media only screen and (min-width: 1000px) {
  #google_translate_element {
    background-color: transparent;
    padding-bottom: 0;
  }
  #google_translate_element .goog-te-gadget {
    margin: auto 0 auto auto;
  }
}

.language_button_container {
    position: absolute;
    bottom: 5px;
    left: 15px;
    display: flex;
    flex-direction: column;
    font-family: "Poppins" !important;
}

.language_button {
    width: 40px;
    height: 40px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    border: none;
    background: rgba(224, 224, 224, 1);
    transition: 0.25s;
}

.language_button:hover {
    transform: scale(1.05);
}

.selected_language {
    background: rgba(92, 224, 210, 1);
}

.language_button:nth-child(2) {
    margin-left: 5px;
}
.taskTopic .box {
    min-height: 100px;
    background-color: #5ce0d2;
    padding: 25px;
    border-radius: 8px;
}

#fromDate-label {
    font-family: "Poppins" !important;
    margin-top: 5px;
}

#fromDate,
#toDate-label {
    font-family: "Poppins" !important;
}

.MuiOutlinedInput-root {
    border-radius: 10px !important;
}

.taskFilter .MuiInputBase-root {
    height: 100% !important;
}

.taskFilter .MuiFormControl-root {
    margin: 14px !important;
    vertical-align: middle !important;
    justify-content: center !important;
}
.learningResources .box {
  min-height: 100px;
  background-color: #5ce0d2;
  padding: 25px;
  border-radius: 8px;
}

.learningResources .cardData {
  padding-top: 242px;
  color: black;
}

.learningResources .cardData p {
  text-decoration: none;
}

.cardData p:first-child {
  margin-block-start: 0px !important;
  margin-block-end: 5px !important;
}

.cardData p :nth-child(2) {
  margin-block-start: 0px !important;
  margin-block-end: 20px !important;
}

.white_color {
  color: "white" !important;
}

.button_container {
  display: flex;
}
.button_container > button {
    border: transparent;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin-right: 10px;
    padding:8px 15px;
}

@media screen and (max-width: 472px) {
  .learningResources {
    padding: 0px !important;
  }
}

.modules .box .upperBox {
    min-height: 150px;
    background-color: #5ce0d2;
    padding: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.makeStyles-root-79 {
    background-color: #edfcfa !important;
}

@media (min-width: 768px) {
    .modules .MuiTabs-flexContainer {
        justify-content: center;
    }
}
.module .cardData {
    padding-top: 262px;
    color: black;
}
.assesment .cardData {
    padding-top: 242px;
    color: white;
}

.tests-links {
    display: flex;
    margin: 1.5rem 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.tests-links p {
    font-size: 20px;
    margin: 0;
    text-align: center;
}

.link-box {
    width: 29%;
    /* height: 5rem; */
    padding: 10px;
    background: #F08080;
    border-radius: 7px;
    color: white;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2rem;
    text-decoration: none;
}

.secondary-box {
    background: #C08497;
}

@media(max-width:768px) {
    .link-box {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;
    }
    .tests-links {
        margin: 0.5rem;
    }
}
.testQuestion .box {
  min-height: 100px;
  background-color: #5ce0d2;
  padding: 25px;
  border-radius: 8px;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.header {
    min-height: 150px;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(92.61deg, #2497F4 0%, #F8AB9D 0%, #F8AB9D 0.01%, #F6918B 99.74%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.slider,
.progressContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2.5rem 0;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.save-btn,
.submit-test-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    outline: none;
    border: none;
    border-radius: 7px;
    background: #FF574B;
    height: 45px;
    color: white;
    cursor: pointer;
}

.save-btn:hover,
.submit-test-btn:hover {
    background: #c43227;
}

.progressBar {
    line-height: 1.7;
    width: 95%;
}

.progressBar p {
    font-weight: bold;
}

.progressBarCont {
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    box-sizing: border-box;
    border-radius: 20px;
    height: 6px;
    width: 80%;
}

.save-btn img {
    margin-right: 10px;
}

.bar {
    background: #4BDF3F;
    height: 100%;
    position: relative;
}

.bar::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -5px;
    background: #4BDF3F;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.bar::after {
    content: '';
    position: absolute;
    right: -1px;
    top: -5px;
    background: #4BDF3F;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.progress-percentage {
    font-size: 18px;
}

.progress-time {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.time-grey {
    color: #AEAEAE;
    margin: 0;
}

.details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.assesment-detail {
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    font-weight: 600;
}

.assesment-detail h1,
.assesment-detail h2 {
    color: white;
    font-weight: bolder;
}

.assesment-detail h1 {
    font-size: 30px;
}

.assesment-detail h2 {
    font-size: 25px;
}

.content {
    font-size: 12px;
    color: #AF4D41;
}

.assessment-time {
    /* margin-top: 3rem; */
    display: flex;
    align-items: center;
    color: white;
}

.month {
    margin-left: 15px;
    font-weight: bolder;
}

.calendar-img {
    position: relative;
}

.day {
    position: absolute;
    top: 0;
    left: 1px;
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerRight div {
    margin-left: 7px;
}

.ranking,
.markCont {
    position: relative;
}

.detail-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-holder p {
    color: white;
    font-size: 12px;
    margin-left: 14px;
}

.rank,
.marks {
    position: absolute;
    top: 0;
    left: 5px;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: #FF574B;
    font-size: 20px;
}

.marks {
    align-items: flex-start;
    justify-content: center;
}

.divide {
    height: 70%;
    width: 2px;
    background: #FF574B;
    transform: rotate(15deg);
}

.mark-text {
    height: 100%;
    margin-top: 20px;
    margin-right: 5px;
}

.total-mark {
    margin-left: 5px;
    margin-top: 30px;
}

.assesmentPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}

.mainCont {
    width: 80%;
}

.assesmentPopupCont {
    min-height: 200px;
    width: 280px;
    border: 1px solid #F68F89;
    box-sizing: border-box;
    border-radius: 23px;
    padding: 10px;
    background: white;
    display: flex;
    line-height: 2.5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error-msg {
    line-height: 1.2;
    margin-top: 13px !important;
}

.error-msg,
.error-text {
    font-size: 16px;
    color: #FF574B;
    margin: 0;
}

.warning-popup {
    position: relative;
}

.warning-symbol {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 2px;
    justify-content: center;
    align-items: center;
}

.warning-symbol svg {
    color: white;
    font-size: 80px;
}

.popupClose {
    height: 35px;
    padding: 10px;
    width: 70%;
    margin: 10px 0;
    background: #F8AB9D
}

.error-text {
    color: black;
}

.answer {
    margin: 5px 0!important;
}

.submit-test-btn {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 0;
    padding: 1rem;
    margin-left: 15px;
    background: #B46B82;
}

.disabled_button {
    background-color: rgb(129, 129, 2) !important;
}

.slider-img {
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
}

.slider-img:hover {
    color: black;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
}

@media(max-width:768px) {
    .headerRight {
        margin: 20px 0;
        line-height: 2;
        justify-content: space-between;
    }
    .slider {
        align-items: center;
    }
    .slider-img {
        width: 30px;
        height: 30px;
    }
    .save-btn {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 10px;
    }
    .headerRight div {
        margin-left: 0px;
    }
    .mainCont {
        width: 100%;
    }
}

@media screen and (max-width:450px) {
    .remark {
        font-size: 0.6em !important;
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        align-self: flex-end;
    }
    .signarture {
        font-size: 0.8em !important;
        width: 150px !important;
    }
    .superhead h6 {
        font-size: 0.9em !important;
        padding-top: 10px !important;
    }
    .save-btn {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        font-size: 0.9em;
        padding: 7px 9px;
    }
    .question_card {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}
.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.desc__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.desc {
    display: flex;
    justify-content: space-around;
}

.desc_image {
    height: auto;
    width: 350px;
    margin-top: auto;
}

.upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.upload>textarea {
    width: 553px;
    height: 248px;
    border: 1px solid #F59B92;
    box-sizing: border-box;
    border-radius: 10px;
}

.or {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bolder;
}

.upload_btn {
    display: flex;
    width: 135px;
    font-size: 18px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    height: 35px;
    background: #F28584;
    border-radius: 35px;
    border: 1px solid #F28584;
}

.upload_btn>img {
    width: 20px;
    height: 20px;
}

.button_details {
    height: 35px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    padding-left: 1px;
    align-items: center;
    text-align: center;
    color: #212121;
    opacity: 0.5
}

.descCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.descCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
}

.descCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F28584;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
    ;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}
.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.fb__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.fb {
    margin-left: 35px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
    align-items: center;
    margin-bottom: 20px;
}

.fb>input {
    width: 350px;
    height: 40px;
    border: 1px solid #F59B92;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 15px;
}

.fbCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.fbCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    margin-top: 10px;
}

.fbCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.fbCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.fb_image {
    height: auto;
    width: 300px;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: 20px;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid#F28584;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media (max-width: 450px) {
    .fb {
        padding-left: 10px !important;
    }
    .fb>input {
        margin-top: 10px;
        margin-right: 22px;
        margin-left: 0px;
    }
    .fb_image {
        margin-left: 0px;
    }
    .fbCard__left {
        text-align: left;
    }
}

@media (max-width:768px) {
    .fb_image {
        height: auto !important;
        width: 90% !important;
    }
    .fb input {
        margin-right: 0;
        width: 90%;
    }
}
.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.mcq__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.mcq {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
}

.mcqCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
}

.mcqCard__left {
    display: flex;
    padding-left: 18px;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.mcqCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.mcqCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.checkbox>.MuiIconButton-label>.MuiSvgIcon-root {
    color: #F28584 !important;
    font-size: 42px !important;
}

.opt1-container {
    border: 1px solid #F59B92;
    padding: 10px;
    width: 50%;
    /* change*/
    /* height: 50px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 68px;
    border-radius: 10px;
    display: block;
    position: relative;
    padding-left: 44px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    font-size: 16px;
}

.opt1-container:hover {
    background: #CDFFE1;
}

.up {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
    /* width: 100%; */
    margin-bottom: -12px;
    margin-top: 50px;
}


/* Hide the browser's default radio button */

.opt1-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 12px;
    left: 17px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 3px solid #F59B92;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.opt1-container:hover input~.checkmark {
    background-color: #F28584;
}


/* When the radio button is checked, add a blue background */

.opt1-container input:checked~.checkmark {
    background-color: #F28584;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.opt1-container input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.opt1-container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #F28584;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F59B92;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}

.top-left {
    flex: 40% 1;
}

.top-right {
    flex: 40% 1;
    justify-content: right;
    padding-right: 20px;
}

.bottom-left {
    flex: 40% 1;
}

.bottom-right {
    flex: 40% 1;
    justify-content: right;
    padding-right: 20px;
}

.main_div {
    display: flex;
}

.ans_mcq_image {
    width: 130px;
    height: auto;
    border-radius: 10px;
}

.imgBlock {
    background-size: cover!important;
    position: relative;
    overflow: hidden;
    max-width: 80px !important;
    height: auto !important;
    max-height: 150px !important;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: -34px;
}

.opt-card-top {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 100% !important;
}

@media (max-width: 450px) {
    .main_div {
        flex-direction: column;
    }
    .mcq>.up {
        width: 55% !important;
    }
    .mcq {
        width: 95%!important;
        display: flex!important;
        flex-direction: column!important;
        padding-left: 15px!important;
    }
    .top-right,
    .bottom-right {
        margin-left: -15px!important;
        padding-right: inherit!important;
    }
    .checkmark {
        top: 10px!important;
        left: 10px!important;
    }
    .opt1-container {
        padding-left: 35px!important;
        font-size: 10px!important;
    }
    .mcqCard__left {
        text-align: left !important;
        margin-left: 10px;
    }
    .up {
        margin-top: 10px !important;
    }
}

@media(max-width:768px) {
    .mcqCard__left {
        padding: 0;
        display: block;
    }
    .remark {
        margin: 0 !important;
    }
}
.question_image {
    display: flex;
    width: 250px;
    height:300px;
    padding:10px;
    margin: auto;
}

.question_image > .q-img {
    max-width: 100%;
    max-height: 100%;
    margin: auto !important;
}
.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.mmcq__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.mmcq {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
}

.mmcqCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.mmcqCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.mmcqCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.mmcqCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 42px !important;
}

/*  */

.main_div {
  display: flex;
  padding:20px;
}

.ans_mmcq_image {
  width: 130px;
  height: auto;
  border-radius: 10px;
}

.imgBlock {
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  max-width: 80px !important;
  height: auto !important;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: -34px;
}

.opt-card-top {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  width: 100% !important;
}

@media (max-width: 450px) {
  .main_div {
    flex-direction: column;
  }
  .mmcq > .up {
    width: 90% !important;
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    display: inline !important;
  }
  .mmcq {
    width: 95% !important;
    /* margin-left: auto!important;
      margin-right: auto!important; */
  }
  .mmcqCard__left {
    text-align: left !important;
  }
  .top-left,
  .bottom-left {
    margin-left: -15px !important;
  }
  /* .top-right,.bottom-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
  .opt1-container {
    width: 100% !important;
    font-size: 13px !important;
  }
  /* .ml-6 {
        margin-left: 0px!important;
    } */
}

@media (max-width: 768px) {
  /*.main_div img {
    width: 80% !important;
    height: auto !important;
    margin: 0 !important;
    max-width: fit-content;
    max-height: fit-content;
    margin-left: -25px !important;
    margin-top: 5px !important;
  }*/
  .mmcq {
    flex-direction: column;
    margin: 0 !important;
  }
  .up {
    width: 90% !important;
  }
  .ml {
    margin-left: 0 !important;
  }
  .mmcq {
    padding-left: 20px !important;
  }
}

.question_image {
    display: flex;
    width: 250px;
    height:300px;
    padding:10px;
    margin: auto;
}

.question_image > .q-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    max-height: 100%;
    margin: auto !important;
}
.superhead {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.one__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.oneWord {
    margin-left: 35px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
    align-items: center;
    margin-bottom: 20px;
}

.oneWord>input {
    width: 350px;
    height: 40px;
    border: 1px solid #F28584;
    margin-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0px 15px;
}

.oneCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.oneCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    margin-top: 10px;
}

.oneCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.oneCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.oneword_image {
    height: auto;
    width: 300px;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: 20px;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 0px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F28584;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: -5px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media (max-width: 450px) {
    .oneWord {
        padding-left: 10px !important;
    }
    .oneWord>input {
        margin-top: 10px;
        margin-right: 22px;
        margin-left: 0px;
        max-width: 250px !important;
    }
    .oneword_image {
        margin-left: 0px;
    }
    .oneCard__left {
        font-size: 0.9em !important;
    }
}

@media(max-width:768px) {
    .remark {
        margin-left: 0 !important;
    }
    .oneword_image {
        width: 90% !important;
        height: auto !important;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }
}
.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.tf__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.tf {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
    align-items: center;
    margin-bottom: 20px;
}

.tfCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.tfCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
}

.tfCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.tfCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.checkbox>.MuiIconButton-label>.MuiSvgIcon-root {
    color: #5AE893 !important;
    font-size: 42px !important;
}

.main_div {
    display: flex;
}

.tf_image {
    height: auto;
    width: 300px;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: 25px;
}

.ans_tf_image {
    width: 130px;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 450px) {
    .tfCard__left {
        text-align: left;
        padding-top: 5px;
    }
    .main_div {
        flex-direction: column;
    }
    .tf>.up {
        width: 45% !important;
    }
    .top-left,
    .top-right {
        display: inline !important;
    }
    .tf {
        width: 95%!important;
    }
    .top-left {
        margin-left: -15px!important;
    }
    /* .top-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
    .opt1-container {
        width: 100%!important;
        font-size: 13px!important;
    }
    .imgBlock {
        margin-left: -25px!important;
    }
}

@media(max-width:768px) {
    .main_div {
        align-items: center;
    }
    .tf {
        flex-direction: column !important;
        padding-left: 5px !important;
        align-items: flex-start;
        margin-left: 30px;
    }
}
.reportCardFilter .filterDivider {
    background-color: black;
    width: 100%;
    height: 2px;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.reportCardFilter {
    border: 2px solid black !important;
    border-radius: 10px;
}
.Login {
    margin: 0px;
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0px;
    z-index: 0;
}

.bg-1 {
    width: 100%;
    height: 150%;
}

.wave {
    position: absolute;
    top: 0;
    z-index: 0;
    opacity: 0.4;
    width: 100%;
    height: 55vh !important;
}
.LoginBox {
    width: 330px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 70%;
    margin: auto;
    padding: 1.5rem 2rem;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Poppins";
}

.top-section {
    width: 100%;
    text-align: center;
    z-index: 2;
}

.top-section h3 {
    margin-block-start: 0px;
    margin-block-end: 0px;
    color: rgba(71, 79, 133, 1);
}

.top-section p {
    margin-block-start: 15px;
    margin-block-end: 0px;
}

.top-section h3 {
    font-size: 1.8em;
    margin-bottom: 5px;
}

.top-section p {
    font-size: 0.75em;
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0px;
    z-index: 2;
}

.input-username {
    outline: none;
    padding: 12px 10px !important;
    background-color: rgba(81, 227, 212, 0.2);
    border: 1px solid rgba(81, 227, 212, 1);
}

.input-container span {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 80% 20%;
    border: 1px solid rgba(81, 227, 212, 1);
}

.input-password {
    outline: none;
    border: none;
    width: 100%;
    margin-top: 0px !important;
}

.toggle-password {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.input-container span,
.input-username,
.signin-btn {
    padding: 10px 10px;
    border-radius: 10px;
    font-family: "Poppins";
    font-size: 0.75em;
}

.input-password {
    font-size: 1em !important;
    margin-bottom: 0px;
    font-family: "Poppins";
}

.input-username:focus,
.input-container span:focus {
    box-shadow: 0px 0px 3px 0px #008c97b5;
}

.signin-btn {
    border: none;
    background: #51e3d4;
    border: none;
    padding: 0.8rem 10px;
    width: 100%;
    box-shadow: 0px 4px 9px 1px #02097e2b;
    color: #474f85;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
}

.signin-btn:hover {
    transform: scale(1.03);
}

.bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.bottom-section p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 0.55em;
}

.bottom-section p a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.bottom-section p a:hover {
    text-decoration: underline;
}

.hidden {
    display: none !important;
}

.ToggleBox {
    width: 100%;
}

.ToggleBox button {
    background-color: transparent;
    font-family: "Poppins";
    font-weight: bold;
    color: #5555f3;
    position: absolute;
    top: 120%;
    left: 45vw;
    right: 0%;
    margin: auto;
    border: none;
    cursor: pointer;
}

#visible,
#invisible {
    color: rgba(81, 227, 212, 1);
}

.linkBox {
    width: 100%;
    text-align: center;
    padding-top: 5px;
}

.linkBox a {
    text-decoration: none;
    font-family: "Poppins";
    font-size: 0.9em;
}

@media screen and (max-width: 800px) {
    .links {
        padding-right: 1rem;
    }
    .ToggleBox button {
        left: 40vw;
    }
}

@media screen and (max-width: 400px) {
    .LoginBox {
        width: 80%;
        padding: 0.9rem;
        padding-right: 1.2rem;
    }
    .ToggleBox button {
        left: 30vw;
    }
}
:root {
  --footer-font-size: 0.8em;
}

footer {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 2rem 0px;
  display: grid;
  grid-template-rows: 90% 10%;
  background-color: #eff7ff;
  z-index: 2;
}

footer div {
  width: 80%;
  margin: 0px auto;
}

.upper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
}

.info {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 60%;
}

.info-img {
  width: 150px !important;
  height: auto;
}

.info span {
  height: 50px;
  width: 180px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.info span a img {
  border-radius: 50%;
  width: 40px;
  height: auto;
  cursor: pointer;
}

#icon1 {
  width: 36px;
  margin-top: 2px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5rem;
}

.links span:nth-child(2) {
  margin: 0px 5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.links span {
  display: flex;
  flex-direction: column;
}

.links span p {
  margin-block-start: 5px;
  margin-block-end: 5px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
}

.links span a {
  margin-bottom: 5px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
  text-decoration: none;
  color: black;
}

.link-heading {
  font-weight: bold;
}

.downloads {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.downloads p {
  margin-block-start: 0px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
}

.downloads a {
  width: 100px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--footer-font-size);
  background-color: #334565;
  color: white;
  padding: 2px 5px;
  margin-bottom: 5px;
  text-decoration: none;
  border-radius: 5px;
}

.lower {
  text-align: left;
}

.lower p {
  font-size: 0.7em;
  font-family: "Poppins";
}

@media screen and (max-width: 900px) {
  footer div {
    width: 90%;
  }
  .links {
    padding-right: 1rem;
  }
  .links span:nth-child(2) {
    margin: 0px 4rem;
  }
}

@media screen and (max-width: 600px) {
  .upper {
    flex-direction: column;
  }
  .upper div {
    margin-bottom: 1rem;
  }
  .info,
  .downloads {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .link {
    padding-right: 0px;
    justify-content: space-around;
    align-items: center;
  }
  .links span {
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media screen and (max-width: 400px) {
  .info {
    flex-direction: column;
  }
  .downloads {
    flex-direction: column;
  }
}

@media screen and (max-width: 472px) {
  #pdf-viewer {
    width: 100%;
    margin: 10px 0;
  }
  video {
    width: 95%;
  }
  iframe {
    width: 99%;
  }
}

.media_player {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.desc__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.desc {
    display: flex;
    justify-content: space-around;
}

.desc_image {
    height: auto;
    width: 350px;
    margin-top: auto;
}

.upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.upload>textarea {
    width: 553px;
    height: 248px;
    border: 1px solid #F59B92;
    box-sizing: border-box;
    border-radius: 10px;
}

.or {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bolder;
}

.upload_btn {
    display: flex;
    width: 135px;
    font-size: 18px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #ffffff;
    height: 35px;
    background: #F28584;
    border-radius: 35px;
    border: 1px solid #F28584;
}

.upload_btn>img {
    width: 20px;
    height: 20px;
}

.button_details {
    height: 35px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    padding-left: 1px;
    align-items: center;
    text-align: center;
    color: #212121;
    opacity: 0.5
}

.descCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
}

.descCard__left {
    display: flex;
    flex: 2 1;
    padding-left: 18px;
    align-items: center;
    text-align: center;
}

.descCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F28584;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
    ;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}
.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.fb__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
  margin-bottom: 10px;
}

.fb {
  margin-left: 35px;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top:15px;
  margin: inherit;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.fb > .fb-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
}
.fb input {
  width: 350px;
  height: 40px;
  border: 1px solid #f59b92;
  margin-left: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 15px;
}

.fbCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.fbCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
}

.fbCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.fbCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.fb_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 20px;
}

.signarture {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 6px;
  width: 225.54px;
  height: 55px;
  background: #ffffff;
  border-bottom: 3px solid#F28584;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-right: 18px;
  margin-left: auto;
  order: 2;
  padding-left: 5px;
}

.last {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .fb {
    padding-left: 10px !important;
  }
  .fb > input {
    margin-top: 10px;
    margin-right: 22px;
    margin-left: 0px;
  }
  .fb_image {
    margin-left: 0px;
  }
  .fbCard__left {
    text-align: left;
  }
}

@media (max-width: 768px) {
  .fb_image {
    height: auto !important;
    width: 90% !important;
  }
  .fb input {
    margin-right: 0;
    width: 90%;
  }
}

.question_image {
  display: flex;
  width: 300px !important;
  height: 300px !important;
  padding: 10px;
  margin: 0 !important;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin:auto !important;
}

.superhead {
    display: flex;
    flex-direction: column;
}

.remark {
    margin-left: auto;
    background: #F8AB9D;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 21px;
    margin-right: 40px;
    font-size: 15px;
    color: #ffffff;
}

.mcq__head {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-left: 11px solid #F6918B;
    border-radius: 9px;
    box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
    border-radius: 15px;
}

.mcq {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    margin: inherit;
}

.mcqCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-wrap: wrap;
}

.mcqCard__left {
    display: flex;
    padding-left: 18px;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 10px;
}

.mcqCard__right {
    margin-top: 10px;
    margin-right: 10px;
}

.mcqCard__right>img {
    height: 24px;
    width: 24px;
    margin: 0 5px;
}

.checkbox>.MuiIconButton-label>.MuiSvgIcon-root {
    color: #F28584 !important;
    font-size: 42px !important;
}

.opt1-container {
    border: 1px solid #F59B92;
    padding: 10px;
    width: 50%;
    /* change*/
    /* height: 50px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 68px;
    border-radius: 10px;
    display: block;
    position: relative;
    padding-left: 44px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    font-size: 16px;
}

.opt1-container:hover {
    background: #CDFFE1;
}

.up {
    display: flex;
    /* flex-direction: row; */
    /* justify-content: space-evenly; */
    /* width: 100%; */
    margin-bottom: -12px;
    margin-top: 50px;
}


/* Hide the browser's default radio button */

.opt1-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.checkmark {
    position: absolute;
    top: 12px;
    left: 17px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 3px solid #F59B92;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.opt1-container:hover input~.checkmark {
    background-color: #F28584;
}


/* When the radio button is checked, add a blue background */

.opt1-container input:checked~.checkmark {
    background-color: #F28584;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.opt1-container input:checked~.checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.opt1-container .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #F28584;
}

.signarture {
    display: flex;
    flex-direction: column;
    padding: 4px 0px 0px 6px;
    width: 225.54px;
    height: 55px;
    background: #FFFFFF;
    border-bottom: 3px solid #F59B92;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 18px;
    margin-left: auto;
    order: 2;
    padding-left: 5px;
}

.last {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
}

.top-left {
    flex: 40% 1;
}

.top-right {
    flex: 40% 1;
    justify-content: right;
    padding-right: 20px;
}

.bottom-left {
    flex: 40% 1;
}

.bottom-right {
    flex: 40% 1;
    justify-content: right;
    padding-right: 20px;
}

.main_div {
    display: flex;
}

.mcq_image {
    height: auto;
    width: 300px;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: auto;
    margin-bottom: 30px;
    /* margin-bottom: auto; */
}

.ans_mcq_image {
    width: 130px;
    height: auto;
    border-radius: 10px;
}

.imgBlock {
    background-size: cover!important;
    position: relative;
    overflow: hidden;
    min-width: 130px;
    min-height: 100px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: -34px;
}

@media (max-width: 450px) {
    .main_div {
        flex-direction: column;
    }
    /* .mcq > .up{
      width: 45% !important;
    }
    .top-left,.top-right,.bottom-left,.bottom-right{
      display: none!important;
    }
    .mcq{
      width: 95%!important;
    }
    .top-left,.bottom-left{
      margin-left: -15px!important;
    }
    .top-right,.bottom-right{
      padding-right: 1px!important;
    }
    .opt1-container{
      width: 100%!important;
      font-size: 13px!important;
    } */
    .mcq>.up {
        width: 55% !important;
    }
    .mcq {
        width: 95%!important;
        display: flex!important;
        flex-direction: column!important;
        padding-left: 15px!important;
    }
    .top-right,
    .bottom-right {
        margin-left: -15px!important;
        padding-right: inherit!important;
    }
    .checkmark {
        top: 10px!important;
        left: 10px!important;
    }
    .opt1-container {
        padding-left: 35px!important;
        font-size: 10px!important;
    }
    /* .top-left,.bottom-left{
      margin-left: 6px!important;
    } */
    .mcqCard__left {
        text-align: left !important;
        margin-left: 10px;
    }
    .up {
        margin-top: 10px !important;
    }
}

@media(max-width:768px) {
    .mcqCard__left {
        padding: 0;
        display: block;
    }
    .mcq_image {
        width: 80%;
        height: auto !important;
        object-fit: cover;
        margin-left: 0;
    }
    .remark {
        margin: 0 !important;
    }
}

.question_image {
    display: flex;
    width: 250px;
    height:300px;
    padding:10px;
    margin: auto;
}

.question_image > .q-img {
    max-width: 100%;
    max-height: 100%;
    margin: auto !important;
}
.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.mmcq__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.mmcq {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
}

.mmcqCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.mmcqCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.mmcqCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.mmcqCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 42px !important;
}

/*  */

.main_div {
  display: flex;
}

.mmcq_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: auto;
  margin-bottom: 30px;
  /* margin-bottom: auto; */
}

.ans_mmcq_image {
  width: 130px;
  height: auto;
  border-radius: 10px;
}

.imgBlock {
  background-size: cover !important;
  position: relative;
  overflow: hidden;
  max-width: 190px !important;
  height: auto !important;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: -34px;
}

@media (max-width: 450px) {
  .main_div {
    flex-direction: column;
  }
  .mmcq > .up {
    width: 90% !important;
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    display: inline !important;
  }
  .mmcq {
    width: 95% !important;
    /* margin-left: auto!important;
      margin-right: auto!important; */
  }
  .mmcqCard__left {
    text-align: left !important;
  }
  .top-left,
  .bottom-left {
    margin-left: -15px !important;
  }
  /* .top-right,.bottom-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
  .opt1-container {
    width: 100% !important;
    font-size: 13px !important;
  }
  /* .ml-6 {
        margin-left: 0px!important;
    } */
}

@media (max-width: 768px) {
  /*.main_div img {
    width: 80% !important;
    height: auto !important;
    margin: 0 !important;
    margin-left: -25px !important;
    margin-top: 5px !important;
  }*/
  .mmcq {
    flex-direction: column;
    margin: 0 !important;
  }
  .up {
    width: 90% !important;
  }
  .ml {
    margin-left: 0 !important;
  }
  .mmcq {
    padding-left: 20px !important;
  }
}

.question_image {
  display: flex;
  width: 250px;
  height: 300px;
  padding: 10px;
  margin: auto;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto !important;
}

.superhead {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.one__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.oneWord {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: inherit;
  align-items: center;
  margin: 20px 35px;
}

.oneWord > input {
  width: 350px;
  height: 40px;
  border: 1px solid #f28584;
  margin: auto;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0px 15px;
}

.oneCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.oneCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
  font-weight: bolder;
  margin-top: 10px;
}

.oneCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.oneCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.oneword_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 20px;
}

.signarture {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 0px 0px;
  width: 225.54px;
  height: 55px;
  background: #ffffff;
  border-bottom: 3px solid #f28584;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: -5px;
  margin-bottom: 10px;
  margin-right: 18px;
  margin-left: auto;
  order: 2;
  padding-left: 5px;
}

.last {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 450px) {
  .oneWord {
    padding-left: 10px !important;
  }
  .oneWord > input {
    margin-top: 10px;
    margin-right: 22px;
    margin-left: 0px;
    max-width: 250px !important;
  }
  .oneword_image {
    margin-left: 0px;
  }
  .oneCard__left {
    font-size: 0.9em !important;
  }
}

@media (max-width: 768px) {
  .remark {
    margin-left: 0 !important;
  }
}

.question_image {
  display: flex;
  width: 300px !important;
  height: 300px !important;
  padding: 10px;
  margin:0 !important;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto !important;
}

.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.tf__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.tf {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
  align-items: center;
  margin-bottom: 20px;
}

.tfCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.tfCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
}

.tfCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.tfCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 42px !important;
}

.main_div {
  display: flex;
}

.tf_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 25px;
}

.ans_tf_image {
  width: 130px;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 450px) {
  .tfCard__left {
    text-align: left;
    padding-top: 5px;
  }
  .main_div {
    flex-direction: column;
  }
  .tf > .up {
    width: 45% !important;
  }
  .top-left,
  .top-right {
    display: inline !important;
  }
  .tf {
    width: 95% !important;
  }
  .top-left {
    margin-left: -15px !important;
  }
  /* .top-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
  .opt1-container {
    width: 100% !important;
    font-size: 13px !important;
  }
  .imgBlock {
    margin-left: -25px !important;
  }
}

@media (max-width: 768px) {
  .main_div {
    align-items: center;
  }
  .tf {
    flex-direction: column !important;
    padding-left: 5px !important;
    align-items: flex-start;
    margin-left: 30px;
  }
}

.question_image {
  display: flex;
  width: 250px;
  height: 300px;
  padding: 10px;
  margin: auto;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto !important;
}

.select__indicator-separator {
    display: none !important;
}

.test_list_filter {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
}

.MuiOutlinedInput-input {
    padding: 9px !important;
}

#test_filter_date_picker-label {
    margin-top: -10px !important;
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 7px 0px #0000005E;
    border-radius: 10px;
    padding: 10px;
}

.search_container {
    width: 100%;
    text-align: right;
}

#searchbar .MuiInput-underline::after {
    border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
    .learningResources {
        padding: 0px !important;
    }
    .test_list_filter {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .test_list_filter .select__value-container {
        width: 250px !important;
    }
    .test_list_filter .makeStyles-select-7,
    .test_list_filter .makeStyles-datePicker-8 {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin-bottom: 7px;
    }
    .test_list_filter .makeStyles-7 {
        padding: 0 !important;
    }
    .test_list_upper_box div h1 {
        font-size: 1.4em;
        text-align: center;
    }
    .test_list_upper_box div h3 {
        font-size: 1.1em;
        margin: 15px auto;
        text-align: center;
    }
}
.dashboard .box .upperBox {
    height: 200px;
    background-color: #5ce0d2;
    padding: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 3rem;
}

.makeStyles-root-28 {
    background-color: #edfcfa !important;
}

.MuiPaper-rounded {
    border-radius: 0 0 15px 15px !important;
}

.MuiTab-root {
    text-transform: none !important;
    font-weight: bold !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiCard-root {
    border: 0.5px solid rgba(33, 33, 33, 0.3) !important;
}

.dashboard_container {
    width: 78% !important;
}

.MuiTabs-indicator {
    border: 3px solid rgba(127, 61, 255, 1);
    border-radius: 23px;
    /* width: 53px !important;
    left:  600px !important; */
}

@media (min-width: 768px) {
    .dashboard .MuiTabs-flexContainer {
        justify-content: center;
    }
    .dashboard_container {
        width: 85%;
    }
}

@media screen and (max-width:420px) {
    .dashboard_container {
        width: 100% !important;
    }
    .upperBox div div h5 {
        margin: auto !important;
    }
    #dashboard_assessment_types .MuiBox-root,
    #dashboard_assessment_types .MuiBox-root .learningResources {
        padding: 24px 0px !important;
    }
}
.updates .inputBox {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    min-height: 75px;
    padding: 14px;
}

.updates .inputBox .inputField {
    width: 74vw;
}

.updates .inputBox .userImage {
    font-size: 60px;
}

.MuiCardHeader-title {
    font-size: 1.2em !important;
    font-family: "Poppins" !important;
    font-weight: bold !important;
}

.MuiCardHeader-subheader {
    font-family: "Poppins" !important;
}

@media only screen and (max-width: 600px) {
    .updates .inputBox .inputField {
        width: auto;
    }
    .updates .inputBox .userImage {
        font-size: 48px;
    }
    .postCard {
        margin-top: -20px !important;
    }
}

.postCard {
    margin-top: 40px;
}

.postCard .input-group input[type="text"] {
    border: 2px solid #e0dcd8;
    border-radius: 40px;
    border-right: none;
}

.postCard .input-group button {
    border: 2px solid #e0dcd8;
    border-radius: 40px;
    border-left: none;
}

.postCard .namePara {
    margin-bottom: 2px;
}
.revisionTask .cardData {
    padding-top: 180px;
}
.flippedClass .cardData {
    padding-top: 215px;
}
.cardData{
    border-radius: 0 0 17px 17px;
    border-top: 1px solid rgba(0, 0, 0, .288);
}
.homework .cardData {
    padding-top: 180px;
}
.recharts-cartesian-axis-tick-value {
    font-size: 0.7em !important;
}

.recharts-label {
    font-size: 0.75em !important;
}

.recharts-legend-wrapper {
    bottom: 50px !important;
    left: 50px !important;
}

.recharts-legend-item {
    font-size: 0.8em !important;
}

@media screen and (max-width:472px) {
    .recharts-cartesian-axis-tick-value {
        font-size: 0.6em !important;
    }
    .recharts-label {
        font-size: 0.7em !important;
    }
    .recharts-legend-wrapper {
        bottom: 0px !important;
        left: 0px !important;
    }
}
.select__indicator-separator {
    display: none !important;
}

.test_list_filter {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
}

.MuiOutlinedInput-input {
    padding: 9px !important;
}

#test_filter_date_picker-label {
    margin-top: -10px !important;
}

.test_list_upper_box {
    /* margin-top: 70rem !important; */
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 7px 0px #0000005E;
    border-radius: 10px;
    padding: 10px;
}

.search_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#searchbar .MuiInput-underline::after {
    border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
    .learningResources {
        padding: 0px !important;
    }
    .test_list_filter {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .test_list_filter .select__value-container {
        width: 250px !important;
    }
    .test_list_filter .makeStyles-select-7,
    .test_list_filter .makeStyles-datePicker-8 {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin-bottom: 7px;
    }
    .test_list_filter .makeStyles-7 {
        padding: 0 !important;
    }
    .test_list_upper_box {
        margin-top: 1rem !important;
    }
    .test_list_upper_box div h1 {
        font-size: 1.4em;
        text-align: center;
    }
    .test_list_upper_box div h3 {
        font-size: 1.1em;
        margin: 15px auto;
        text-align: center;
    }
}
.analytics_question_card {
    display: flex;
    position: relative !important;
    width: 45% !important;
    flex-direction: column;
    align-items: center;
    border-radius: 2%;
    justify-content: center;
    margin-left: 30px;
    margin-bottom: 30px;
    padding: 10px;
}

.question_analytics_card {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-left: 30px; */
    margin-bottom: 30px;
}

.questionanalytics_section_layer {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    margin-left: 60px !important;
    margin-bottom: 30px;
}

.analytics_question_layer {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    margin-left: 30px;
    margin-bottom: 30px;
    justify-content: space-evenly;
    padding: 10px;
    box-shadow: 0px 0px 7px 0px #00000040;
    border-left: 10px solid rgba(242, 134, 132, 0.75) !important;
    border-bottom: 10px solid rgba(242, 134, 132, 0.75) !important;
}

.option_label {
    border-right: 1px solid #0000001a;
    flex: 0.1 1 !important;
}

.analytics_question_stat {
    max-width: 200px !important;
    margin-left: 10px;
    margin-bottom: 10px;
    height: 30px;
    background-color: #c4c4c494;
}

.analytics_question_content {
    margin-right: auto;
}

.analytics_question_stats {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.analytics_question_option {
    margin-bottom: 5px;
    margin-left: 5px !important;
}

.analytics_question_content {
    margin-right: 2rem;
}

.analytics_question_fill_in_the_blank {
    margin-top: 25px !important;
    margin-bottom: 25px;
}

.correct_answer_fill_in_the_blank {
    margin-top: 15px !important;
    font-size: smaller;
    font-weight: 700;
}

.option_tof {
    font-weight: 700;
}

.back_btn {
    position: absolute;
    margin-top: 1rem;
    margin-left: 1rem;
    z-index: 999;
}

.Question_Analytics_Responsive_Container {
    min-width: 387px !important;
    min-height: 387px !important;
}

.Question_Analytics_Diversion {
    min-height: 400px;
    min-width: 1px;
    background-color: #000;
}

.questionanalytics_card_list {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 325px;
    right: 75px;
}

.analytics_question_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.analytics_question_heading p:first-child {
    color: rgba(242, 134, 132, 0.75) !important;
    font-size: 1.2em;
    font-weight: bold;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.analytics_question_heading_card {
    border: 1px solid rgba(242, 134, 132, 0.75) !important;
    font-size: 0.7em;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 5px 2px;
    margin-right: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid #F69792 !important;
}

.analytics_question_statement {
    font-size: 0.9em;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1100px) {
    .question_analytics_card {
        flex-direction: column;
    }
    .Question_Analytics_Diversion {
        display: none;
    }
    .Question_Analytics_Responsive_Container {
        min-width: 300px !important;
        min-height: 300px !important;
    }
    .questionanalytics_section_layer {
        margin-left: 2rem !important;
    }
    .questionanalytics_card_list {
        top: 500px;
        right: 30px;
    }
}

@media screen and (max-width: 540px) {
    .questionanalytics_section_layer {
        margin-left: 1.2rem !important;
    }
}
.select__indicator-separator {
    display: none !important;
}

.test_list_filter {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
}

.MuiOutlinedInput-input {
    padding: 9px !important;
}

#test_filter_date_picker-label {
    margin-top: -10px !important;
}

.test_list_upper_box {
    /* margin-top: 70rem !important; */
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 7px 0px #0000005E;
    border-radius: 10px;
    padding: 10px;
}

.search_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#searchbar .MuiInput-underline::after {
    border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
    .learningResources {
        padding: 0px !important;
    }
    .test_list_filter {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .test_list_filter .select__value-container {
        width: 250px !important;
    }
    .test_list_filter .makeStyles-select-7,
    .test_list_filter .makeStyles-datePicker-8 {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin-bottom: 7px;
    }
    .test_list_filter .makeStyles-7 {
        padding: 0 !important;
    }
    .test_list_upper_box {
        margin-top: 1rem !important;
    }
    .test_list_upper_box div h1 {
        font-size: 1.4em;
        text-align: center;
    }
    .test_list_upper_box div h3 {
        font-size: 1.1em;
        margin: 15px auto;
        text-align: center;
    }
}
.recharts-cartesian-axis-tick-value {
    font-size: 0.7em !important;
}

.recharts-label {
    font-size: 0.75em !important;
}

.recharts-legend-wrapper {
    bottom: 50px !important;
    left: 50px !important;
}

.recharts-legend-item {
    font-size: 0.8em !important;
}

@media screen and (max-width:472px) {
    .recharts-cartesian-axis-tick-value {
        font-size: 0.6em !important;
    }
    .recharts-label {
        font-size: 0.7em !important;
    }
    .recharts-legend-wrapper {
        bottom: 0px !important;
        left: 0px !important;
    }
}
.select__indicator-separator {
    display: none !important;
}

.test_list_filter {
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
}

.MuiOutlinedInput-input {
    padding: 9px !important;
}

#test_filter_date_picker-label {
    margin-top: -10px !important;
}

.test_list_upper_box {
    /* margin-top: 70rem !important; */
}

.table_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 7px 0px #0000005E;
    border-radius: 10px;
    padding: 10px;
}

.search_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#searchbar .MuiInput-underline::after {
    border: 1px solid #dbf3f2;
}

@media screen and (max-width: 450px) {
    .learningResources {
        padding: 0px !important;
    }
    .test_list_filter {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-evenly;
    }
    .test_list_filter .select__value-container {
        width: 250px !important;
    }
    .test_list_filter .makeStyles-select-7,
    .test_list_filter .makeStyles-datePicker-8 {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin-bottom: 7px;
    }
    .test_list_filter .makeStyles-7 {
        padding: 0 !important;
    }
    .test_list_upper_box {
        margin-top: 1rem !important;
    }
    .test_list_upper_box div h1 {
        font-size: 1.4em;
        text-align: center;
    }
    .test_list_upper_box div h3 {
        font-size: 1.1em;
        margin: 15px auto;
        text-align: center;
    }
}
@media screen and (max-width:900px) {

    ul{
        color:black !important;
    }
        ul .recharts-default-legend{
            padding-left: 150px !important;
        }
    
}
.MuiTableCell-stickyHeader{
    position:static !important;
}
.rowHover:hover{
    background-color: #DEBEC8 !important;
    opacity: 0.5;

}
/* @media */
.analytics_question_card {
    display: flex;
    position: relative !important;
    width: 45% !important;
    flex-direction: column;
    align-items: center;
    border-radius: 2%;
    justify-content: center;
    margin-left: 30px;
    margin-bottom: 30px;
    padding: 10px;
}

.question_analytics_card {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin-left: 30px; */
    margin-bottom: 30px;
}

.questionanalytics_section_layer {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    margin-left: 60px !important;
    margin-bottom: 30px;
}

.analytics_question_layer {
    display: flex;
    position: relative !important;
    width: 90% !important;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    margin-left: 30px;
    margin-bottom: 30px;
    justify-content: space-evenly;
    padding: 10px;
    box-shadow: 0px 0px 7px 0px #00000040;
    border-left: 10px solid rgba(180, 107, 130, 0.79);
    border-bottom: 10px solid rgba(180, 107, 130, 0.79);
}

.option_label {
    border-right: 1px solid #0000001a;
    flex: 0.1 1 !important;
}

.analytics_question_stat {
    max-width: 200px !important;
    margin-left: 10px;
    margin-bottom: 10px;
    height: 30px;
    background-color: #c4c4c494;
}

.analytics_question_content {
    margin-right: auto;
}

.analytics_question_stats {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.analytics_question_option {
    margin-bottom: 5px;
    margin-left: 5px !important;
}

.analytics_question_content {
    margin-right: 2rem;
}

.analytics_question_fill_in_the_blank {
    margin-top: 25px !important;
    margin-bottom: 25px;
}

.correct_answer_fill_in_the_blank {
    margin-top: 15px !important;
    font-size: smaller;
    font-weight: 700;
}

.option_tof {
    font-weight: 700;
}

.back_btn {
    position: absolute;
    margin-top: 1rem;
    margin-left: 1rem;
    z-index: 999;
}

.Question_Analytics_Responsive_Container {
    min-width: 387px !important;
    min-height: 387px !important;
}

.Question_Analytics_Diversion {
    min-height: 400px;
    min-width: 1px;
    background-color: #000;
}

.questionanalytics_card_list {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 325px;
    right: 75px;
}

.analytics_question_heading {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.analytics_question_heading p:first-child {
    color: rgba(192, 132, 151, 1);
    font-size: 1.2em;
    font-weight: bold;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

.analytics_question_heading_card {
    border: 1px solid rgba(242, 134, 132, 0.75);
    font-size: 0.7em;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 5px 2px;
    margin-right: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 1px solid #F69792;
}

.analytics_question_statement {
    font-size: 0.9em;
    margin-bottom: 2rem;
}

@media screen and (max-width: 1100px) {
    .question_analytics_card {
        flex-direction: column;
    }
    .Question_Analytics_Diversion {
        display: none;
    }
    .Question_Analytics_Responsive_Container {
        min-width: 300px !important;
        min-height: 300px !important;
    }
    .questionanalytics_section_layer {
        margin-left: 2rem !important;
    }
    .questionanalytics_card_list {
        top: 500px;
        right: 30px;
    }
}

@media screen and (max-width: 540px) {
    .questionanalytics_section_layer {
        margin-left: 1.2rem !important;
    }
}
