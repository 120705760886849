.superhead {
  display: flex;
  flex-direction: column;
}

.remark {
  margin-left: auto;
  background: #f8ab9d;
  border-radius: 8px 8px 0px 0px;
  padding: 8px 21px;
  margin-right: 40px;
  font-size: 15px;
  color: #ffffff;
}

.tf__head {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 11px solid #f6918b;
  border-radius: 9px;
  box-shadow: 0px 4px 7px rgb(33 33 33 / 18%);
  border-radius: 15px;
}

.tf {
  display: flex;
  flex-wrap: wrap;
  padding-left: 30px;
  margin: inherit;
  align-items: center;
  margin-bottom: 20px;
}

.tfCard {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.tfCard__left {
  display: flex;
  flex: 2 1;
  padding-left: 18px;
  align-items: center;
  text-align: center;
}

.tfCard__right {
  margin-top: 10px;
  margin-right: 10px;
}

.tfCard__right > img {
  height: 24px;
  width: 24px;
  margin: 0 5px;
}

.checkbox > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #5ae893 !important;
  font-size: 42px !important;
}

.main_div {
  display: flex;
}

.tf_image {
  height: auto;
  width: 300px;
  border-radius: 15px;
  margin-left: 25px;
  margin-top: 25px;
}

.ans_tf_image {
  width: 130px;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 450px) {
  .tfCard__left {
    text-align: left;
    padding-top: 5px;
  }
  .main_div {
    flex-direction: column;
  }
  .tf > .up {
    width: 45% !important;
  }
  .top-left,
  .top-right {
    display: inline !important;
  }
  .tf {
    width: 95% !important;
  }
  .top-left {
    margin-left: -15px !important;
  }
  /* .top-right{
      padding-right: 1px!important;
      margin-left: 6px!important;
    } */
  .opt1-container {
    width: 100% !important;
    font-size: 13px !important;
  }
  .imgBlock {
    margin-left: -25px !important;
  }
}

@media (max-width: 768px) {
  .main_div {
    align-items: center;
  }
  .tf {
    flex-direction: column !important;
    padding-left: 5px !important;
    align-items: flex-start;
    margin-left: 30px;
  }
}

.question_image {
  display: flex;
  width: 250px;
  height: 300px;
  padding: 10px;
  margin: auto;
}

.question_image > .q-img {
  max-width: 100%;
  max-height: 100%;
  margin: auto !important;
}
