:root {
  --footer-font-size: 0.8em;
}

footer {
  width: 100%;
  height: fit-content;
  padding: 2rem 0px;
  display: grid;
  grid-template-rows: 90% 10%;
  background-color: #eff7ff;
  z-index: 2;
}

footer div {
  width: 80%;
  margin: 0px auto;
}

.upper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
}

.info {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  width: 60%;
}

.info-img {
  width: 150px !important;
  height: auto;
}

.info span {
  height: 50px;
  width: 180px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.info span a img {
  border-radius: 50%;
  width: 40px;
  height: auto;
  cursor: pointer;
}

#icon1 {
  width: 36px;
  margin-top: 2px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5rem;
}

.links span:nth-child(2) {
  margin: 0px 5rem;
  width: fit-content;
}

.links span {
  display: flex;
  flex-direction: column;
}

.links span p {
  margin-block-start: 5px;
  margin-block-end: 5px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
}

.links span a {
  margin-bottom: 5px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
  text-decoration: none;
  color: black;
}

.link-heading {
  font-weight: bold;
}

.downloads {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
}

.downloads p {
  margin-block-start: 0px;
  font-family: "Poppins";
  font-size: var(--footer-font-size);
}

.downloads a {
  width: 100px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--footer-font-size);
  background-color: #334565;
  color: white;
  padding: 2px 5px;
  margin-bottom: 5px;
  text-decoration: none;
  border-radius: 5px;
}

.lower {
  text-align: left;
}

.lower p {
  font-size: 0.7em;
  font-family: "Poppins";
}

@media screen and (max-width: 900px) {
  footer div {
    width: 90%;
  }
  .links {
    padding-right: 1rem;
  }
  .links span:nth-child(2) {
    margin: 0px 4rem;
  }
}

@media screen and (max-width: 600px) {
  .upper {
    flex-direction: column;
  }
  .upper div {
    margin-bottom: 1rem;
  }
  .info,
  .downloads {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .link {
    padding-right: 0px;
    justify-content: space-around;
    align-items: center;
  }
  .links span {
    margin: 0px !important;
    padding: 0px !important;
  }
}

@media screen and (max-width: 400px) {
  .info {
    flex-direction: column;
  }
  .downloads {
    flex-direction: column;
  }
}
