@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
body::before {
  display: block;
  content: "";
  height: 60px;
}

body {
  position: static !important;
}

* {
  font-family: "Poppins" !important;
}

.dropdown-item:active {
  background-color: #c4c7ca !important;
}

a:hover {
  color: black !important;
}

.MuiTableCell-root,
.MuiTableCell-head {
  font-family: "Poppins" !important;
}

.MuiTableCell-head {
  color: #53c2b7 !important;
}

.MuiFormControl-root,
.MuiTextField-root {
  font-family: "Poppins" !important;
}

.card-radius {
  border-radius: 17px !important;
  box-shadow: #00000040 0px 0px 5px 1px;
}

.card-height {
  min-height: 190px !important;
}

.card-img {
  height: 115px;
  padding: 5px;
}

.card {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  border: 0.5px solid #5ce0d2;
  box-shadow: 0px 1px 7px 0px #5ce0d2;
}

.card:hover {
  transform: scale(1.1);
}

.card-thumbnail {
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 10px;
}

.mar-p {
  margin: 0;
  margin-bottom: 5px;
}

@media screen and (max-width: 472px) {
  .card {
    width: 100% !important;
    margin: auto;
  }
  .learningResources {
    margin-top: -10px !important;
  }
  .learningResources div div h5 {
    text-align: center !important;
    font-size: 0.8em !important;
  }
  .learningResources div div span {
    margin: auto !important;
  }
  .learningResources div div span h1 {
    font-size: 1.4em !important;
  }
  .container {
    margin-top: 20px !important;
  }
  .MuiBox-root {
    padding: 24px 0px !important;
  }
  .card:hover {
    transform: scale(1.01);
  }
  .MuiTabs-flexContainer {
    justify-content: space-evenly !important;
  }
}

/* Change the font size and font family */
#google_translate_element {
  position: relative;
  padding: 15px 2.5% 15px 2.5%;
  overflow: hidden;
  background-color: rgb(237, 252, 250);
}
#google_translate_element .goog-te-gadget {
  width: 200px;
  overflow: hidden;
  margin: auto;
}
#google_translate_element select {
  width: 100%;
  font-size: 16px;
  color:  #7F3DFF;
  font-family: "Poppins", sans-serif;
  border: transparent;
  background: transparent;
}

/* Change the background color of the button */
#google_translate_element div.goog-te-gadget-simple {
  background-color: #f5f5f5;
}

/* Change the color of the button text */
#google_translate_element div.goog-te-gadget-simple span {
  color: #333;
}

/* Change the hover color of the button */
#google_translate_element div.goog-te-gadget-simple:hover {
  background-color: #333;
}

/* Change the hover color of the button text */
#google_translate_element div.goog-te-gadget-simple:hover span {
  color: #f5f5f5;
}

.goog-te-gadget > span:nth-child(2) {
  display: none !important;
}
.goog-te-banner-frame {
  display: none !important;
}

.skiptranslate > iframe {
  display: none !important;
}

@media only screen and (min-width: 1000px) {
  #google_translate_element {
    background-color: transparent;
    padding-bottom: 0;
  }
  #google_translate_element .goog-te-gadget {
    margin: auto 0 auto auto;
  }
}
