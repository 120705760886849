.taskTopic .box {
    min-height: 100px;
    background-color: #5ce0d2;
    padding: 25px;
    border-radius: 8px;
}

#fromDate-label {
    font-family: "Poppins" !important;
    margin-top: 5px;
}

#fromDate,
#toDate-label {
    font-family: "Poppins" !important;
}

.MuiOutlinedInput-root {
    border-radius: 10px !important;
}

.taskFilter .MuiInputBase-root {
    height: 100% !important;
}

.taskFilter .MuiFormControl-root {
    margin: 14px !important;
    vertical-align: middle !important;
    justify-content: center !important;
}