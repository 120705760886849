@media screen and (max-width: 472px) {
  #pdf-viewer {
    width: 100%;
    margin: 10px 0;
  }
  video {
    width: 95%;
  }
  iframe {
    width: 99%;
  }
}
