.modules .box .upperBox {
    min-height: 150px;
    background-color: #5ce0d2;
    padding: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
}

.makeStyles-root-79 {
    background-color: #edfcfa !important;
}

@media (min-width: 768px) {
    .modules .MuiTabs-flexContainer {
        justify-content: center;
    }
}