.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.header {
    min-height: 150px;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(92.61deg, #2497F4 0%, #F8AB9D 0%, #F8AB9D 0.01%, #F6918B 99.74%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.slider,
.progressContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2.5rem 0;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.save-btn,
.submit-test-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    outline: none;
    border: none;
    border-radius: 7px;
    background: #FF574B;
    height: 45px;
    color: white;
    cursor: pointer;
}

.save-btn:hover,
.submit-test-btn:hover {
    background: #c43227;
}

.progressBar {
    line-height: 1.7;
    width: 95%;
}

.progressBar p {
    font-weight: bold;
}

.progressBarCont {
    background: #FFFFFF;
    border: 1px solid #BCBCBC;
    box-sizing: border-box;
    border-radius: 20px;
    height: 6px;
    width: 80%;
}

.save-btn img {
    margin-right: 10px;
}

.bar {
    background: #4BDF3F;
    height: 100%;
    position: relative;
}

.bar::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -5px;
    background: #4BDF3F;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.bar::after {
    content: '';
    position: absolute;
    right: -1px;
    top: -5px;
    background: #4BDF3F;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.progress-percentage {
    font-size: 18px;
}

.progress-time {
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.time-grey {
    color: #AEAEAE;
    margin: 0;
}

.details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.assesment-detail {
    display: flex;
    flex-direction: column;
    line-height: 1.7;
    font-weight: 600;
}

.assesment-detail h1,
.assesment-detail h2 {
    color: white;
    font-weight: bolder;
}

.assesment-detail h1 {
    font-size: 30px;
}

.assesment-detail h2 {
    font-size: 25px;
}

.content {
    font-size: 12px;
    color: #AF4D41;
}

.assessment-time {
    /* margin-top: 3rem; */
    display: flex;
    align-items: center;
    color: white;
}

.month {
    margin-left: 15px;
    font-weight: bolder;
}

.calendar-img {
    position: relative;
}

.day {
    position: absolute;
    top: 0;
    left: 1px;
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerRight div {
    margin-left: 7px;
}

.ranking,
.markCont {
    position: relative;
}

.detail-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-holder p {
    color: white;
    font-size: 12px;
    margin-left: 14px;
}

.rank,
.marks {
    position: absolute;
    top: 0;
    left: 5px;
    font-weight: bolder;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: #FF574B;
    font-size: 20px;
}

.marks {
    align-items: flex-start;
    justify-content: center;
}

.divide {
    height: 70%;
    width: 2px;
    background: #FF574B;
    transform: rotate(15deg);
}

.mark-text {
    height: 100%;
    margin-top: 20px;
    margin-right: 5px;
}

.total-mark {
    margin-left: 5px;
    margin-top: 30px;
}

.assesmentPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}

.mainCont {
    width: 80%;
}

.assesmentPopupCont {
    min-height: 200px;
    width: 280px;
    border: 1px solid #F68F89;
    box-sizing: border-box;
    border-radius: 23px;
    padding: 10px;
    background: white;
    display: flex;
    line-height: 2.5;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error-msg {
    line-height: 1.2;
    margin-top: 13px !important;
}

.error-msg,
.error-text {
    font-size: 16px;
    color: #FF574B;
    margin: 0;
}

.warning-popup {
    position: relative;
}

.warning-symbol {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 2px;
    justify-content: center;
    align-items: center;
}

.warning-symbol svg {
    color: white;
    font-size: 80px;
}

.popupClose {
    height: 35px;
    padding: 10px;
    width: 70%;
    margin: 10px 0;
    background: #F8AB9D
}

.error-text {
    color: black;
}

.answer {
    margin: 5px 0!important;
}

.submit-test-btn {
    width: fit-content;
    height: 0;
    padding: 1rem;
    margin-left: 15px;
    background: #B46B82;
}

.disabled_button {
    background-color: rgb(129, 129, 2) !important;
}

.slider-img {
    cursor: pointer;
    transition: 0.3s;
    border-radius: 50%;
}

.slider-img:hover {
    color: black;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
}

@media(max-width:768px) {
    .headerRight {
        margin: 20px 0;
        line-height: 2;
        justify-content: space-between;
    }
    .slider {
        align-items: center;
    }
    .slider-img {
        width: 30px;
        height: 30px;
    }
    .save-btn {
        width: fit-content;
        padding: 10px;
    }
    .headerRight div {
        margin-left: 0px;
    }
    .mainCont {
        width: 100%;
    }
}

@media screen and (max-width:450px) {
    .remark {
        font-size: 0.6em !important;
        width: fit-content !important;
        align-self: flex-end;
    }
    .signarture {
        font-size: 0.8em !important;
        width: 150px !important;
    }
    .superhead h6 {
        font-size: 0.9em !important;
        padding-top: 10px !important;
    }
    .save-btn {
        height: fit-content;
        font-size: 0.9em;
        padding: 7px 9px;
    }
    .question_card {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}